<script>
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  authMethods,
} from "@/state/helpers";
import store from '@/state/store'

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false
    };
  },
  computed: {
    currentUser(){
      return store.getters['auth/currentUser']
    },
    loginError() {
      return store.getters['auth/loginError']
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required },
    password: { required }
  },
  methods: {
    ...authMethods,
    async tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          this.$loading(true)
          await this.login({
            username: this.email,
            password: this.password,
          })
          this.$loading(false)
      }
    }
  },
  watch:{
    currentUser(val){
      if (val && val.username && val.username.length > 0) {
        setTimeout(() => {
          //같은 곳으로 들어갈 경우 나오는 에러를 제외하고 출력하도록 설정
          this.$router.push('/').catch(err => {
            if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
            ) {
              console.log(err)
            }
          })
        }, 200)
      }
    },
    loginError(val) {
      if (val != null) {
        this.resultMessage = val
        Swal.fire("로그인 실패", this.resultMessage, "fail");
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="60" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">환영 합니다!</h4>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">아이디</label>
                            <input
                              type="account"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="아이디를 입력하세요."
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                              autofocus
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">아이디를 입력하세요.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">비밀번호</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="비밀번호를 입력하세요."
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >비밀번호를 입력하세요.</div>
                          </div>

                          <div class="custom-control custom-checkbox">
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              class="custom-control-input"-->
<!--                              id="customControlInline"-->
<!--                            />-->
<!--                            <label-->
<!--                              class="custom-control-label"-->
<!--                              for="customControlInline"-->
<!--                            >기억하기</label>-->
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >로그인</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>